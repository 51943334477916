import { PureComponent } from "react";
import { List } from "immutable";
import { deepDictMerge } from "./merge";
import humanize from 'humanize-duration';
import { Container, Table } from "react-bootstrap";

class RouteServiceTimesViewer extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            routeList: List(),
        }

        this.getAggregationsPromise = () => {
            return this.props.unScoreIt.getAggregationStatuses(
                this.props.calculationId)
                .then(response => {
                    return {
                        "aggregations": response.data
                    };
                });
        }

        this.getCalculationPromise = () => {
            return this.props.unScoreIt.getCalculation(
                this.props.calculationId)
                .then(response => {
                    return {
                        "name": response.data.name,
                        "serviceId": response.data.serviceId,
                        "durations": response.data.durations
                    };
                });
        }

        this.getRoutesServiceTimesPromise = (duration) => {
            return this.props.unScoreIt.getRouteServiceTimes(
                this.props.calculationId, duration)
                .then(response => {
                    return { "routeServiceTimes": response.data }
                })
        }

        this.getRouteNameMapPromise = (serviceId) => {
            return this.props.unRideIt.getRoutes(serviceId)
                .then(response => {
                    const routes = response.data;
                    return { "routes": routes }
                })
        }

        this.getRouteCountsPromise = (duration) => {
            return this.props.unScoreIt.getRouteCounts(this.props.calculationId, duration).then(response => {
                return { "routeCounts": response.data["routeCounts"] }
            })
        }

        this.getSectorRouteCountsPromise = (duration, sector) => {
            return this.props.unScoreIt.getSectorRouteCounts(this.props.calculationId, sector, duration).then(response => {
                return { "routeCounts": response.data["routeCounts"] }
            })
        }
    }

    componentDidMount() {
        Promise.all([this.getCalculationPromise(), this.getAggregationsPromise()])
            .then(respones => {
                const data = respones.reduce((flat, part) => {
                    return deepDictMerge(flat, part);
                }, {});


                const serviceId = data["serviceId"]

                let duration;
                if (this.props.duration) {
                    duration = this.props.duration
                } else {
                    duration = data["durations"][0]
                }

                const promises = [Promise.resolve(data), this.getRouteNameMapPromise(serviceId), this.getRoutesServiceTimesPromise(duration)];
                if ("ROUTE_COUNT" in data["aggregations"]) {
                    let countsPromise;
                    if (this.props.sector) {
                        countsPromise = this.getSectorRouteCountsPromise(duration, this.props.sector)
                    } else {
                        countsPromise = this.getRouteCountsPromise(duration)
                    }
                    promises.push(countsPromise);
                }
                return Promise.all(promises)
            })
            .then(respones => {
                const combined = respones.reduce((flat, part) => {
                    return deepDictMerge(flat, part);
                }, {});

                const routeServiceTimes = combined["routeServiceTimes"];
                const routes = combined["routes"];
                let routeList;
                let hasCounts;
                let hasTime;

                if ("routeCounts" in combined) {
                    const routeCounts = combined["routeCounts"]
                    if (this.props.sector === undefined) {
                        hasCounts = true;
                        hasTime = true

                        routeList = routeServiceTimes.map(
                            routeServiceTime => {
                                const routeIndex = routeServiceTime["index"];
                                return { "id": routeIndex, "name": routes[routeIndex].routeName, "time": routeServiceTime["duration"], "tripCount": routeServiceTime["tripCount"], "count": routeCounts[routeIndex] }
                            }
                        );
                    } else {
                        hasCounts = true;
                        hasTime = false

                        routeList = routeCounts.map(
                            routeId => {
                                return { "id": routeId, "name": routes[routeId], "inbound": routeCounts[routeId]["inbound"], "outbound": routeCounts[routeId]["outbound"] }
                            }
                        )
                    }
                } else {
                    hasCounts = false;
                    hasTime = true;
                    routeList = routeServiceTimes.map(
                        routeServiceTime => {
                            const routeIndex = routeServiceTime["index"];
                            return { "id": routeIndex, "name": routes[routeIndex].routeName, "time": routeServiceTime["duration"], "tripCount": routeServiceTime["tripCount"] }
                        }
                    );
                }


                this.setState({ "name": combined["name"], "routeList": List(routeList), "hasCounts": hasCounts, "hasTime": hasTime });
            })
    }

    render() {
        let sort

        if (this.state.hasCounts && (this.props.sector !== undefined)) {
            sort = (r => -((r["inbound"] || 0) + (r["outbound"] || 0)));
        } else if (this.state.hasCounts) {
            sort = (r => -(r["count"] || 0) / (r["time"] || 0));
        } else {
            sort = (r => -r["time"]);
        }
        return (
            <Container fluid>
                <h1>{this.state.name}</h1>

                <Table bordered responsive striped>
                    <tbody>
                        <tr><th>Route</th>{this.state.hasTime ? (<><th>In-Service Time</th><th>Seconds</th><th>Trip Count</th></>) : null}{(this.state.hasCounts && this.state.hasTime) ? (<><th>Journeys Using Route</th><th>Journeys per Second</th></>) : null}{(this.state.hasCounts && this.props.sector !== undefined) ? (<><th>Inbound Count</th><th>Outbound Count</th></>) : null}</tr>
                        {this.state.routeList.sortBy(sort).map(route => {
                            return (
                                <tr key={route["id"]}><td>{route["name"]}</td>
                                    {this.state.hasTime ? (<><td align="right">{humanize((route["time"] || 0) * 1000, { units: ['h', 'm', 's'] })}</td>
                                        <td align="right">{route["time"] || 0}</td>
                                        <td align="right">{route["tripCount"] || 0}</td></>) : null}
                                    {(this.state.hasCounts && this.state.hasTime) ? (<>
                                        <td align="right">{(route["count"] || 0).toLocaleString()}</td>
                                        <td align="right">{((route["count"] || 0) / (route["time"] || 0)).toFixed(4).toLocaleString()}</td>
                                    </>) : null}
                                    {this.state.hasCounts && (this.props.sector !== undefined) ? (<><td>{route["inbound"]}</td><td>{route["outbound"]}</td></>) : null}</tr>
                            );
                        })}

                    </tbody>
                </Table>

            </Container >
        )
    }
}

export default RouteServiceTimesViewer;