import React from 'react';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import {
    CalculationComparisonInformation,
    CalculationInformation,
    SectorSelector
} from './calc-info';

import { Set } from 'immutable';

import getSectorInformation from './sector-info'


class InfoPanel extends React.PureComponent {

    render() {
        const sectorIds = Set(this.props.sectors.map(sector => sector.id))

        return (
            <Row>
                <Col>
                    {this.props.baseline.get('id') ?
                        (<CalculationComparisonInformation
                            colorScheme={this.props.colorScheme}
                            transformer={this.props.transformer}
                            expandedSector={this.props.expandedSector}
                            calculation={this.props.calculation}
                            baseline={this.props.baseline}
                            gridName={this.props.gridName}
                            networks={this.props.calculation.get('networks')}
                            sectorCount={this.props.sectors.length}
                            setDuration={this.props.setDuration}
                            setBaselineDuration={this.props.setBaselineDuration}
                            inServiceSeconds={this.props.inServiceSeconds}
                            baselineInServiceSeconds={this.props.baselineInServiceSeconds}
                        />) :
                        (<CalculationInformation
                            colorScheme={this.props.colorScheme}
                            transformer={this.props.transformer}
                            calculation={this.props.calculation}
                            expandedSector={this.props.expandedSector}
                            gridName={this.props.gridName}
                            sectorCount={this.props.sectors.length}
                            setDuration={this.props.setDuration}
                            inServiceSeconds={this.props.inServiceSeconds}
                        />)}
                </Col>
                <Col>
                    <SectorSelector
                        sectors={this.props.sectors}
                        selectedSector={this.props.selectedSector}
                        setSector={this.props.setSector} />
                    {(this.props.selectedSector !== undefined && sectorIds.includes(this.props.selectedSector - 1)) ?
                        getSectorInformation(
                            this.props.colorScheme,
                            this.props.calculation,
                            this.props.baseline,
                            this.props.selectedSector,
                            this.props.sectors,
                            this.props.setSector,
                            this.props.unScoreIt,
                            this.props.expandedSector) : null}
                </Col>
            </Row>
        );

    }

}

export default InfoPanel