import React from 'react'

import {
    CalculationComparisonInformation,
    CalculationInformation,
    SectorSelector,
} from './calc-info'
import { Set } from 'immutable'

import getSectorInformation from './sector-info'

import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form'

import naturalCompare from 'string-natural-compare'

class CalculationControls extends React.PureComponent {

    constructor(props) {
        super(props)

        this.state = {
            showAggregations: false
        }

        this.showAggregations = () => {
            this.setState({ "showAggregations": true });
        }

        this.hideAggregations = () => {
            this.setState({ "showAggregations": false });
        }
    }

    render() {
        const publicLink = ("/access/view/" + this.props.calculation.get("id")) +
            (!!this.props.baseline.get("id") ? ("/baseline/" + this.props.baseline.get("id")) : "");
        const sectorIds = Set(this.props.sectors.map(sector => sector.id))
        return (
            <>
                <Row>
                    <Col>
                        {this.props.baseline.get('id') ?
                            (<CalculationComparisonInformation
                                calculation={this.props.calculation}
                                baseline={this.props.baseline}
                                expandedSector={this.props.expandedSector}
                                setDuration={this.props.setDuration}
                                setBaselineDuration={this.props.setBaselineDuration}
                                inServiceSeconds={this.props.inServiceSeconds}
                                baselineInServiceSeconds={this.props.baselineInServiceSeconds}
                            />) :
                            (<CalculationInformation
                                calculation={this.props.calculation}
                                expandedSector={this.props.expandedSector}
                                setDuration={this.props.setDuration}
                                inServiceSeconds={this.props.inServiceSeconds}
                            />)}
                    </Col>
                    <Col>
                        <SectorSelector
                            sectors={this.props.sectors}
                            selectedSector={this.props.selectedSector}
                            setSector={this.props.setSector} />
                        {(this.props.selectedSector !== undefined && sectorIds.includes(this.props.selectedSector - 1)) ?
                            getSectorInformation(
                                this.props.colorMapping,
                                this.props.calculation,
                                this.props.baseline,
                                this.props.selectedSector,
                                this.props.sectors,
                                this.props.setSector,
                                this.props.unScoreIt,
                                this.props.expandedSector) : null}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <InputGroup>
                            <InputGroup.Text>
                                Set Baseline
                            </InputGroup.Text>
                            <Form.Select
                                value={this.props.baseline.get("id")}
                                onChange={this.props.setBaseline}>
                                <option key="" value="">No baseline</option>
                                {this.props.comparableCalculations
                                    .sort((c1, c2) => naturalCompare(c1.name, c2.name))
                                    .map((calculation) => {
                                        return (
                                            <option key={calculation.id}
                                                value={calculation.id}>
                                                {calculation.name}
                                            </option>
                                        )
                                    })}
                            </Form.Select>
                        </InputGroup>
                    </Col>
                </Row>
                <a href={publicLink} target="_blank" rel="noreferrer">Public Link</a>
            </>);
    }
}

export default CalculationControls