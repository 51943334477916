import { PureComponent } from "react";
import AccessMap, { NO_SELECTION_MODE } from "./map";
import { DefaultColorScheme } from './color';
import { setViewport } from "./access-management";
import { merge } from "./merge";
import { Set } from 'immutable'

class GridView extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            "viewport": {
                "latitude": 39.8283,
                "longitude": -98.5795,
                "zoom": 13,
            },
            "sectors": [],
            "overlaySectors": Set([]),
            "overlay": [],
        }

        this.getSectorsPromise = (grid) => {
            return this.props.gridClient.getSectors(grid).then((sectorRes) => {
                const reducer = (numberedSectors, sector) => {
                    numberedSectors[sector.id] = sector;
                    return numberedSectors;
                };

                const sectors = sectorRes.data.reduce(reducer, []);
                return { "sectors": sectors };
            });
        };

        this.getGridOverlaySectorsPromise = (gridOverlayId) => {
            return this.props.gridClient.getGridOverlaySectors(gridOverlayId).then((res) => {

                return { "overlaySectors": Set(res.data) };
            });
        };

        this.getGridOverlayDataPromise = (gridOverlayId) => {
            return this.props.gridClient.getGridOverlayData(gridOverlayId).then((res) => {

                return res.data;
            });
        };

        this.getOverlayPromise = (overlayId) => {
            return this.props.gridClient.getOverlay(overlayId).then((res) => {

                return { "overlay": res.data };
            });
        }

        this.getCenterPromise = (gridId) => {
            return this.props.gridClient.getGrid(gridId).then(res => {
                const coordinates = res.data.center.split(",");
                return {
                    "viewport": {
                        "latitude": coordinates[0],
                        "longitude": coordinates[1],
                    }
                }
            })
        }

        this.setViewport = (viewport) => setViewport(this, viewport);
    }

    componentDidMount() {
        Promise.all([
            this.getGridOverlaySectorsPromise(this.props.gridOverlayId),
            this.getGridOverlayDataPromise(this.props.gridOverlayId)])
            .then(results => {
                const newState = merge(results);
                return Promise.all([
                    Promise.resolve(newState),
                    this.getOverlayPromise(newState.overlayId),
                    this.getSectorsPromise(newState.gridId),
                    this.getCenterPromise(newState.gridId)
                ])
            })
            .then(results => {
                const newState = merge(results);
                this.setState(newState)
            });
    }

    render() {


        return (
            <>
                <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
                    <AccessMap
                        doubleClickZoom={false}
                        visible={true}
                        width="auto"
                        height="auto"
                        mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
                        colorScheme={new DefaultColorScheme()}
                        viewport={this.state.viewport}
                        setViewport={this.setViewport}
                        selectionMode={NO_SELECTION_MODE}
                        expandedSectorTransformations
                        ={[]}
                        baselineCenterPoints
                        ={[]}
                        baselineCenterSectors
                        ={[]}
                        minRouteZoom={0}
                        busy={false}
                        sectors={this.state.sectors}
                        overlay={this.state.overlay}
                        overlaySectors={this.state.overlaySectors}
                    />
                </div >
            </>
        )
    }
}

export default GridView;