import React from 'react';
import humanize from 'humanize-duration';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form'
import { isEntirelyExhaustive, isExhaustive } from './calculation';
import { INBOUND_COUNT_TRANSFORMATION, INBOUND_RATIO_TRANSFORMATION, OUTBOUND_COUNT_TRANSFORMATION, OUTBOUND_RATIO_TRANSFORMATION } from './transformer';
import { Card } from 'react-bootstrap';

class JourneyPanel extends React.PureComponent {
    render() {
        const sectorTransformations = this.props.sectorTransformations;
        let journeyText;
        if (!sectorTransformations.isEmpty()) {
            /* Inbound and outbound are with respect to the reached sectors, so
              these are swapped for our overall aggregations. */
            const inboundSum = sectorTransformations.get(
                OUTBOUND_COUNT_TRANSFORMATION).sum;
            const outboundSum = sectorTransformations.get(
                INBOUND_COUNT_TRANSFORMATION).sum;
            const inboundRatio = sectorTransformations.get(
                OUTBOUND_RATIO_TRANSFORMATION).avg;
            const outboundRatio = sectorTransformations.get(
                INBOUND_RATIO_TRANSFORMATION).avg;
            const composite = BigInt(inboundSum) * BigInt(outboundSum);
            const compositeRatio = inboundRatio * outboundRatio;


            if (this.props.isExhaustive) {
                journeyText = (
                    <>
                        <div>Completed Journeys: {inboundSum.toLocaleString()} ({(inboundRatio).toFixed(4)})</div>
                        <div>Overall Composite Ratio: {compositeRatio.toFixed(4)}</div>
                    </>)
            } else {
                const geoMean = Math.sqrt(inboundSum) * Math.sqrt(outboundSum);
                const meanRatio = Math.sqrt(compositeRatio)
                journeyText = (<>
                    <div>Completed Inbound Journeys: {inboundSum.toLocaleString()} ({inboundRatio.toFixed(4)})</div>
                    <div>Completed Outbound Journeys: {outboundSum.toLocaleString()} ({outboundRatio.toFixed(4)})</div>
                    <div>Mean: {geoMean.toLocaleString()} ({meanRatio.toFixed(4)})</div>
                    <div>Composite Score: {composite.toLocaleString()} ({compositeRatio.toFixed(4)})</div>
                </>)
            }
        } else {
            journeyText = null;
        }
        return journeyText;
    }
}

export class CalculationInformation extends React.PureComponent {
    constructor(props) {
        super(props);
        this.setDuration = (event) => {
            const duration = parseInt(event.target.value)
            this.props.setDuration(duration);
        }
    }

    render() {
        const calculation = this.props.calculation;
        const sectorTransformations = calculation.get(
            "sectorTransformations");
        const expandedSectorTransformations = calculation.get(
            "expandedSectorTransformations");

        let expandedJourneyPanel;
        if (this.props.expandedSector === undefined) {
            expandedJourneyPanel = null;
        } else {
            const expandedSector = this.props.expandedSector;
            expandedJourneyPanel = (
                <Card>
                    <Card.Body>
                        <Card.Title>For Sector {expandedSector}</Card.Title>
                        <JourneyPanel
                            sectorTransformations={expandedSectorTransformations}
                            isExhaustive={false} />
                    </Card.Body>
                </Card>
            );
        }

        const networks = calculation.get("networks");
        const durations = calculation.get("durations");
        const inServiceSeconds = this.props.inServiceSeconds;
        const startTime = calculation.get("startTime");
        const endTime = calculation.get("endTime");
        const selectedDuration = calculation.get("selectedDuration");

        return (<div>
            <JourneyPanel sectorTransformations={sectorTransformations}
                isExhaustive={isExhaustive(calculation)} />
            {expandedJourneyPanel}
            <Table bordered size="sm">
                <tbody>
                    <tr>
                        <td>Time Budget</td>
                        <td>
                            <select value={selectedDuration} onChange={this.setDuration}>
                                {durations.map((duration) => {
                                    return (
                                        <option key={duration.toString() + "-duration"} value={duration}>{humanize(duration * 1000)}</option>
                                    )
                                })
                                }
                            </select></td>
                    </tr>
                    <tr>
                        <td>Span</td>
                        <td>{startTime}—{endTime}</td>
                    </tr>
                    <tr>
                        <td>Service</td>
                        <td>
                            <ul>
                                {networks.map(network => {
                                    return (<a key={network.id} href={"/network/" + network.id}><li>{network.name}</li></a>);
                                })
                                }
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>In Service Time</td>
                        <td>{humanize(inServiceSeconds * 1000, { units: ['h', 'm', 's'] })}</td>
                    </tr>
                </tbody>
            </Table>
        </div >);
    }
}

export class SectorSelector extends React.PureComponent {
    constructor(props) {
        super(props)
        this.setSector = (event) => {
            const value = event.target.value;
            const intValue = parseInt(value);
            const sanitizedInt = isNaN(intValue) ? undefined : intValue;

            return this.props.setSector(sanitizedInt);
        };
    }

    render() {
        return (
            <Form>
                <Form.Group controlId="selectSector">

                    <Form.Label>Select Sector</Form.Label>
                    <Form.Control
                        type="text"
                        value={(this.props.selectedSector === undefined)
                            ? "" : this.props.selectedSector}
                        onChange={this.setSector} />

                </Form.Group>
            </Form>
        );
    }
}

function getComparisonRows(transformations, baselineTransformations,
    exhaustive) {

    /* Inbound and outbound are with respect to the reached sectors, so these 
       are swapped for our overall aggregations. */
    const inboundCount
        = transformations.get(OUTBOUND_COUNT_TRANSFORMATION).sum;
    const outboundCount
        = transformations.get(INBOUND_COUNT_TRANSFORMATION).sum;
    const inbound = transformations.get(OUTBOUND_RATIO_TRANSFORMATION).avg;
    const outbound = transformations.get(INBOUND_RATIO_TRANSFORMATION).avg;

    const combined = inbound * outbound;
    const mean = Math.sqrt(inboundCount) * Math.sqrt(outboundCount)
    const meanRatio = Math.sqrt(combined)

    const baselineInboundCount
        = baselineTransformations.get(OUTBOUND_COUNT_TRANSFORMATION).sum;
    const baselineOutboundCount
        = baselineTransformations.get(INBOUND_COUNT_TRANSFORMATION).sum;
    const baselineInbound
        = baselineTransformations.get(OUTBOUND_RATIO_TRANSFORMATION).avg;
    const baselineOutbound
        = baselineTransformations.get(INBOUND_RATIO_TRANSFORMATION).avg;

    const baselineCombined = baselineInbound * baselineOutbound;
    const baselineMean = Math.sqrt(baselineInboundCount) * Math.sqrt(baselineOutboundCount)
    const baselineMeanRatio = Math.sqrt(baselineCombined)

    let journeySection;
    if (exhaustive) {
        journeySection = (
            <>
                <tr>
                    <td>Completed Journeys</td>
                    <td>{baselineInboundCount.toLocaleString()} ({baselineInbound.toFixed(4)}) </td>
                    <td> {inboundCount.toLocaleString()} ({inbound.toFixed(4)})</td>
                </tr>
            </>)
    } else {
        journeySection = (
            <>
                <tr>
                    <td>Inbound Completed Journeys</td>
                    <td>{baselineInboundCount.toLocaleString()} ({baselineInbound.toFixed(4)}) </td>
                    <td> {inboundCount.toLocaleString()} ({inbound.toFixed(4)})</td>
                </tr>
                <tr>
                    <td>Outbound Completed Journeys</td>
                    <td>{baselineOutboundCount.toLocaleString()} ({baselineOutbound.toFixed(4)})</td>
                    <td>{outboundCount.toLocaleString()} ({outbound.toFixed(4)})</td>
                </tr>
                <tr>
                    <td>Mean</td>
                    <td>{baselineMean.toLocaleString()} ({baselineMeanRatio.toFixed(4)})</td>
                    <td>{mean.toLocaleString()} ({meanRatio.toFixed(4)})</td>
                </tr>
            </>
        );
    }

    return (<>
        {journeySection}
        <tr>
            <td>Overall Composite Ratio</td>
            <td>
                {baselineCombined.toFixed(4)}
            </td>
            <td>
                {combined.toFixed(4)}
            </td>
        </tr>
    </>);
}

export class CalculationComparisonInformation extends React.PureComponent {

    constructor(props) {
        super(props);
        this.setDuration = (event) => {
            const duration = parseInt(event.target.value)
            this.props.setDuration(duration);
        }

        this.setBaselineDuration = (event) => {
            const duration = parseInt(event.target.value)
            this.props.setBaselineDuration(duration);
        }
    }

    render() {
        const calculation = this.props.calculation;
        const transformations = calculation.get("sectorTransformations");

        const baseline = this.props.baseline;
        const baselineTransformations = baseline.get("sectorTransformations");

        const exhaustive = isEntirelyExhaustive(calculation, baseline)

        const calculationComparisonRows = getComparisonRows(transformations,
            baselineTransformations, exhaustive);

        let expandedRows;
        if (this.props.expandedSector) {
            expandedRows = (
                <>
                    <tr>
                        <th colSpan={3}>For sector {this.props.expandedSector}</th>
                    </tr>
                    {getComparisonRows(
                        calculation.get("expandedSectorTransformations"),
                        baseline.get("expandedSectorTransformations"), false)}
                </>);
        } else {
            expandedRows = null;
        }

        const networks = calculation.get("networks");
        const durations = calculation.get("durations");
        const inServiceSeconds = this.props.inServiceSeconds;
        const startTime = calculation.get("startTime")
        const endTime = calculation.get("endTime")

        const baselineNetworks = baseline.get("networks");
        const baselineDurations = baseline.get("durations");
        const baselineInServiceSeconds = this.props.baselineInServiceSeconds;
        const baselineStartTime = baseline.get("startTime")
        const baselineEndTime = baseline.get("endTime")

        return (
            <>
                <Table bordered size="sm">
                    <tbody>
                        <tr>
                            <th></th>
                            <th>
                                {baseline.get("name")}
                            </th>
                            <th>
                                {calculation.get("name")}
                            </th>
                        </tr>

                        {calculationComparisonRows}
                        {expandedRows}

                        <tr>

                            <td>Time Budget</td>
                            <td>
                                <select value={this.props.baseline.get("selectedDuration")} onChange={this.setBaselineDuration}>
                                    {baselineDurations.map((duration) => {
                                        return (
                                            <option key={duration.toString() + "-duration-baseline"} value={duration}>{humanize(duration * 1000)}</option>
                                        )
                                    })
                                    }
                                </select>
                            </td>
                            <td>
                                <select value={this.props.calculation.get("selectedDuration")} onChange={this.setDuration}>
                                    {durations.map((duration) => {
                                        return (
                                            <option key={duration.toString() + "-duration"} value={duration}>{humanize(duration * 1000)}</option>
                                        )
                                    })
                                    }
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td>Span</td>
                            <td>{baselineStartTime}—{baselineEndTime}</td>
                            <td>{startTime}—{endTime}</td>
                        </tr>
                        <tr>
                            <td>Service</td>
                            <td>
                                <ul>
                                    {baselineNetworks.map(network => {
                                        return (<a key={network.id} href={"/network/" + network.id}><li>{network.name}</li></a>);
                                    })}
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    {networks.map(network => {
                                        return (<a key={network.id} href={"/network/" + network.id}><li>{network.name}</li></a>);
                                    })}
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>In Service Time</td>
                            <td>{humanize(baselineInServiceSeconds
                                * 1000, { units: ['h', 'm', 's'] })}</td>
                            <td>{humanize(inServiceSeconds
                                * 1000, { units: ['h', 'm', 's'] })}</td>
                        </tr>
                    </tbody>
                </Table ></>);
    }
}

