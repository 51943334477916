import axios from 'axios';

class UnScoreItClient {

    static BEARER_PREFIX = "Bearer "

    constructor() {
        this.host = process.env.REACT_APP_UNSCOREIT_ENDPOINT;
    }

    getCalculations() {
        const url = this.host + "/calculations";
        return axios.get(url);
    }

    getCalculation(calculationId) {
        const url = this.host + "/calculations/" + calculationId;
        return axios.get(url);
    }

    getReachCounts(calculationId, duration) {
        const url = this.host + "/calculations/"
            + calculationId + "/reach-counts/durations/" + duration;
        return axios.get(url);
    }

    getPatchReachCounts(calculationId, duration, patch) {
        const url = this.host + "/calculations/"
            + calculationId + "/patches/" + patch
            + "/reach-counts/durations/" + duration;
        return axios.get(url);
    }

    getFrequentWalkshed(id) {
        const url = this.host + "/frequent-walksheds/" + id;
        return axios.get(url);
    }

    getRouteCounts(calculationId, duration) {
        const url = this.host + "/calculations/"
            + calculationId + "/route-counts/durations/" + duration;
        return axios.get(url);
    }

    getSectorReachCounts(calculationId, sector, duration) {
        const url = this.host + "/calculations/"
            + calculationId + "/sector/" + sector + "/reach-counts/durations/"
            + duration;
        return axios.get(url);
    }

    createPointAccessCalculation(tokenProvider, name, gridId,
        networkIds, baseTime, span, samplingInterval, durations, centers,
        reporters, walkingBehaviorType) {
        const url = this.host + "/calculations:point"
        const request = {
            "name": name, "gridId": gridId, "networkIds": networkIds,
            "baseTime": baseTime, "span": span, "samplingInterval": samplingInterval,
            "durations": durations, "centers": centers, "reporters": reporters,
            "traversalBehavior": walkingBehaviorType
        };
        return axios.post(url, request, this.createAuthConfig(tokenProvider));
    }

    createSectorAccessCalculation(tokenProvider, name, gridId,
        networkIds, baseTime, span, samplingInterval, durations, sector,
        reporters, walkingBehaviorType) {
        const url = this.host + "/calculations:sector";
        const request = {
            "name": name, "gridId": gridId, "networkIds": networkIds,
            "baseTime": baseTime, "span": span, "samplingInterval": samplingInterval,
            "durations": durations, "sector": sector, "reporters": reporters,
            "traversalBehavior": walkingBehaviorType
        };
        return axios.post(url, request, this.createAuthConfig(tokenProvider));
    }

    createNetworkAccessCalculation(tokenProvider, name, gridId,
        networkIds, baseTime, span, samplingInterval, durations, reporters,
        walkingBehaviorType) {
        const url = this.host + "/calculations:network";
        const request = {
            "name": name, "gridId": gridId,
            "networkIds": networkIds, "baseTime": baseTime, "span": span,
            "samplingInterval": samplingInterval, "durations": durations,
            "reporters": reporters,
            "traversalBehavior": walkingBehaviorType
        };
        return axios.post(url, request, this.createAuthConfig(tokenProvider));
    }

    createRouteCountAggregation(tokenProvider, calculationId) {
        const url = this.host + "/calculations/"
            + calculationId + "/route-counts";
        const request = {};
        return axios.post(url, request, this.createAuthConfig(tokenProvider));
    }

    getComparables(gridId) {
        const url = this.host + "/calculations"
            + '?gridId=' + gridId;
        return axios.get(url)
    }

    getSectorRouteCounts(calculationId, selectedSector, duration) {
        const url = this.host + "/calculations/"
            + calculationId + "/sectors/" + selectedSector + "/route-counts/durations/" + duration;
        return axios.get(url);
    }

    getPathCounts(calculationId, selectedSector) {
        const url = this.host + "/calculations/"
            + calculationId + "/path-count/" + selectedSector;
        return axios.get(url);
    }

    getRouting(calculationId, routeName, direction,
        routingVariant) {
        const url = this.host + "/calculations/" + calculationId
            + "/routes/" + routeName + "/" + direction + "/routing-variants/"
            + routingVariant;
        return axios.get(url);
    }

    getTripsForRouting(calculationId, routeName, direction,
        routingVariant) {
        const url = this.host + "/calculations/"
            + calculationId + "/routes/" + routeName + "/" + direction + "/routing-variants/"
            + routingVariant + "/trips";
        return axios.get(url);
    }

    getTimingVariants(calculationId, routeName, direction,
        routingVariant) {
        const url = this.host + "/calculations/"
            + calculationId + "/routes/" + routeName + "/" + direction + "/routing-variants/"
            + routingVariant + "/timing-variants";
        return axios.get(url);
    }

    getTiming(calculationId, routeName, direction,
        routingVariant, timingVariant) {
        const url = this.host + "/calculations/"
            + calculationId + "/routes/" + routeName + "/" + direction + "/routing-variants/"
            + routingVariant + "/timing-variants/" + timingVariant;
        return axios.get(url);
    }

    getTripsForTiming(calculationId, routeName, direction,
        routingVariant, timingVariant) {
        const url = this.host + "/calculations/"
            + calculationId + "/routes/" + routeName + "/" + direction + "/routing-variants/"
            + routingVariant + "/timing-variants/" + timingVariant + "/trips";
        return axios.get(url);
    }

    getAggregationStatuses(calculationId) {
        const url = this.host + "/calculations/"
            + calculationId + "/aggregations"
        return axios.get(url);
    }

    getRouteServiceTimes(calculationId, duration) {
        const url = this.host + "/calculations/"
            + calculationId + "/route-service-times/durations/" + duration
        return axios.get(url);
    }

    getRoutingServiceTimes(calculationId, duration) {
        const url = this.host + "/calculations/"
            + calculationId + "/routing-service-times/durations/" + duration
        return axios.get(url);
    }

    getSegmentServiceTimes(calculationId, duration) {
        const url = this.host + "/calculations/"
            + calculationId + "/segment-service-times/durations/" + duration
        return axios.get(url);
    }

    getServiceTime(calculationId, duration) {
        const url = this.host + "/calculations/"
            + calculationId + "/service-time/durations/" + duration
        return axios.get(url);
    }

    getSegmentJourneys(calculationId, duration) {
        const url = this.host + "/calculations/"
            + calculationId + "/segment-journeys/durations/" + duration
        return axios.get(url);
    }

    getSegmentSectors(calculationId, routing, sequence, duration) {
        const url = this.host + "/calculations/"
            + calculationId + "/segment-sectors/routings/" + routing +
            "/sequence/" + sequence + "/durations/" + duration;
        return axios.get(url);
    }



    createAuthConfig(tokenProvider) {
        return {
            headers: {
                "Authorization": UnScoreItClient.BEARER_PREFIX
                    + tokenProvider.getBearerToken()
            }
        }
    }

}

export default UnScoreItClient;
